export const data =
[
   {
       id: 19,
       title:"ما يقال عند سماع الأذان",
       data:[
                `يَقُولُ مِثْلَ مَا يَقُولُ الـمُؤَذِّنُ إلاَّ فِي "حَيَّ عَلَى الصَّلاةِ وَحَيَّ عَلَى الفَلاَحِ" فَيَقُولُ: "لاَ حَوْلَ وَلا قُوَّةَ إلاَّ باللَّهِ". `,
                `عنْ سَعْدِ بْن أَبي وقَّاصٍ رضِيَ اللَّه عنْهُ عَن النبي صَلّى اللهُ عَلَيْهِ وسَلَّم أَنَّهُ قَالَ: "مَنْ قَال حِينَ يسْمعُ المُؤذِّنَ : أَشْهَد أَنْ لا إِله إِلاَّ اللَّه وحْدهُ لا شَريك لهُ ، وَأَنَّ مُحمَّداً عبْدُهُ وَرسُولُهُ ، رضِيتُ بِاللَّهِ ربًّا ، وبمُحَمَّدٍ رَسُولاً ، وبالإِسْلامِ دِينًا ، غُفِر لَهُ ذَنْبُهُ". رواه مسلم . `,
                `عَنْ عبْدِ اللَّهِ بْنِ عَمرِو بْنِ العاصِ رضِيَ اللَّه عنْهُما أَنه سَمِع رسُولَ اللَّهِ صَلّى اللهُ عَلَيْهِ وسَلَّم يقُولُ : "إِذا سمِعْتُمُ النِّداءَ فَقُولُوا مِثْلَ ما يَقُولُ ، ثُمَّ صَلُّوا علَيَّ ، فَإِنَّهُ مَنْ صَلَّى علَيَّ صَلاةً صَلَّى اللَّه عَلَيْهِ بِهَا عشْراً ، ثُمَّ سلُوا اللَّه لي الْوسِيلَةَ ، فَإِنَّهَا مَنزِلَةٌ في الجنَّةِ لا تَنْبَغِي إِلاَّ لعَبْدٍ منْ عِباد اللَّه وَأَرْجُو أَنْ أَكُونَ أَنَا هُو ، فَمنْ سَأَل ليَ الْوسِيلَة حَلَّتْ لَهُ الشَّفاعَةُ". رواه مسلم . `,
                `عَنْ جابرٍ بن عبد الله رضَي اللَّه عنهماأَنَّ رَسُولَ اللَّهِ صَلّى اللهُ عَلَيْهِ وسَلَّم قَالَ: "من قَال حِين يسْمعُ النِّداءَ : اللَّهُمَّ رَبَّ هذِهِ الدَّعوةِ التَّامَّةِ ، والصَّلاةِ الْقَائِمةِ، آت مُحَمَّداً الْوسِيلَةَ ، والْفَضَيِلَة، وابْعثْهُ مقَامًا محْمُوداً الَّذي وعَدْتَه ، حلَّتْ لَهُ شَفَاعتي يوْم الْقِيامِة" .رواه البخاري . `
            ]
   },
   {
       id: 20,
       title:"ما يقال بعد سماع الأذان",
       data:[
                "اللَّهُمَّ صَلِّ وَسَلِّمْ وَبَارِكْ عَلَى سَيِّدِنَا مُحَمَّدٍ.",
                "اللَّهُمَّ رَبَّ هَذِهِ الدَّعْوَةِ التَّامَّةِ، والصَّلاةِ القَائِمَةِ، آتِ مُـحَمَّداً الوَسِيْلَةَ والفَضِيْلَةَ، وابْعَثْهُ مَقَاماً مَـحْمُوداً الَّذِي وَعَدْتَهُ، إنَّكَ لا تُخْلِفُ الـمِيْعَادِ."
            ]
   },
   {
       id: 21,
       title:"ما يقال بين الأذان والإقامة",
       data:[
                "ما بين الأذان والإقامة فالدعاء عندئذٍ مرغّب فيه ومستحب.",
                `قَالَ رَسُولُ اللَّهِ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ: "الدُّعَاءُ لَا يُرَدُّ بَيْنَ الْأَذَانِ وَالْإِقَامَةِ".`,
                `قَالَ رَسُولُ اللَّهِ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ: "إِنَّ الدُّعَاءَ لَا يُرَدُّ بَيْنَ الْأَذَانِ وَالْإِقَامَةِ فَادْعُوا". `
            ]
   },
   {
       id: 22,
       title:"نص صيغة الأذان",
       data:[
                "اللهُ أكْبَرُ ، اللهُ أكْبَرُ",
                "اللهُ أكْبَرُ ، اللهُ أكْبَرُ",
                "أشْهَدُ أنَّ لا إلَهَ إلاَّ اللهُ",
                "أشْهَدُ أنَّ لا إلَهَ إلاَّ اللهُ",
                "أشْهَدُ أنَّ مُحَمَّداً رَسُولُ اللهِ",
                "أشْهَدُ أنَّ مُحَمَّداً رَسُولُ اللهِ",
                "حَيَّ عَلَى الصَّلاةِ",
                "حَيَّ عَلَى الصَّلاةِ",
                "حَيَّ عَلَى الفَلاحِ",
                "حَيَّ عَلَى الفَلاحِ",
                "اللهُ أكْبَرُ ، اللهُ أكْبَرُ",
                "لاَ إلَهَ إلاَّ اللهُ"
            ]
   },
   {
       id: 23,
       title:"نص صيغة أذان الفجر",
       data:[
                "اللهُ أكْبَرُ ، اللهُ أكْبَرُ",
                "اللهُ أكْبَرُ ، اللهُ أكْبَرُ",
                "أشْهَدُ أنَّ لا إلَهَ إلاَّ اللهُ",
                "أشْهَدُ أنَّ لا إلَهَ إلاَّ اللهُ",
                "أشْهَدُ أنَّ مُحَمَّداً رَسُولُ اللهِ",
                "أشْهَدُ أنَّ مُحَمَّداً رَسُولُ اللهِ",
                "حَيَّ عَلَى الصَّلاةِ",
                "حَيَّ عَلَى الصَّلاةِ",
                "حَيَّ عَلَى الفَلاحِ",
                "حَيَّ عَلَى الفَلاحِ",
                "الصلاةُ خيرٌ من النوم",
                "الصلاةُ خيرٌ من النوم",
                "اللهُ أكْبَرُ ، اللهُ أكْبَرُ",
                "لاَ إلَهَ إلاَّ اللهُ"
            ]
   },
   {
       id: 24,
       title:"نص صيغة الإقامة",
       data:[
                "اللهُ أكْبَرُ ، اللهُ أكْبَرُ",
                "أشْهَدُ أنَّ لا إلَهَ إلاَّ اللهُ",
                "أشْهَدُ أنَّ مُحَمَّداً رَسُولُ اللهِ",
                "حَيَّ عَلَى الصَّلاةِ",
                "حَيَّ عَلَى الفَلاحِ",
                "قد قامت الصلاةُ",
                "قد قامت الصلاةُ",
                "اللهُ أكْبَرُ ، اللهُ أكْبَرُ",
                "لاَ إلَهَ إلاَّ اللهُ"
            ]
   },
]