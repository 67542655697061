export const data = 
[
     {
         id   : 48,
         title: "دُعَاءُ الذَّهَابِ إلَى المَسْجِدِ",
         data :[
                   `اللّهُـمَّ اجْعَـلْ في قَلْبـي نورا ، وَفي لِسـاني نورا، وَاجْعَـلْ في سَمْعي نورا، وَاجْعَـلْ في بَصَري نورا، وَاجْعَـلْ مِنْ خَلْفي نورا، وَمِنْ أَمامـي نورا، وَاجْعَـلْ مِنْ فَوْقـي نورا ، وَمِن تَحْتـي نورا .اللّهُـمَّ أَعْطِنـي نورا.`
               ]
     },
     {
         id   : 49,
         title: "دُعَاءُ دُخُولِ المَسْجِدِ",
         data :[
                   `يَبْدَأُ بِرِجْلِهِ اليُمْنَى، وَيَقُولُ:
                   أَعوذُ باللهِ العَظيـم وَبِوَجْهِـهِ الكَرِيـم وَسُلْطـانِه القَديـم مِنَ الشّيْـطانِ الرَّجـيم، بِسْمِ اللَّهِ، وَالصَّلاةُ وَالسَّلامُ عَلَى رَسُولِ الله، اللّهُـمَّ افْتَـحْ لي أَبْوابَ رَحْمَتـِك. `
               ]
     },
     {
         id   : 50,
         title: "دُعَاءُ الخُرُوجِ مِنَ المَسْجِدِ",
         data :[
                   `يَبْدَأُ بِرِجْلِهِ الْيُسْرَى، وَيَقُولُ:
                   بِسْـمِ اللَّـهِ وَالصَّلاةُ وَالسَّلامُ عَلَى رَسُولِ اللَّهِ، اللَّهُمَّ إنِّي أَسْأَلُكَ مِنْ فَضْلِكَ، اللَّهُمَّ اعْصِمْنِي مِنَ الشَّيْطَانِ الرَّجِيم. `
               ]
     },
]