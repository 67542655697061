export const data =[
  {
      id: 1, 
      text: "سُبْحَانَ اللَّهِ ",
      count: 100,
      info:"يكتب له ألف حسنة أو يحط عنه ألف خطيئة."
  },
  {
      id: 2, 
      text: "سُبْحَانَ اللَّهِ وَبِحَمْدِهِ ",
      count: 100,
      info:"حُطَّتْ خَطَايَاهُ وَإِنْ كَانَتْ مِثْلَ زَبَدِ الْبَحْرِ. لَمْ يَأْتِ أَحَدٌ يَوْمَ الْقِيَامَةِ بِأَفْضَلَ مِمَّا جَاءَ بِهِ إِلَّا أَحَدٌ قَالَ مِثْلَ مَا قَالَ أَوْ زَادَ عَلَيْهِ."
  },
  {
      id: 3, 
      text: "سُبْحَانَ اللَّهِ وَالْحَمْدُ لِلَّهِ",
      count: 100,
      info:"تَمْلَآَنِ مَا بَيْنَ السَّمَاوَاتِ وَالْأَرْضِ."
  },
  {
      id: 4, 
      text: "سُبْحَانَ اللهِ العَظِيمِ وَبِحَمْدِهِ",
      count: 100,
      info:"غرست له نخلة في الجنة (أى عدد)."
  },
  {
      id: 5, 
      text: "سُبْحَانَ اللَّهِ وَبِحَمْدِهِ ، سُبْحَانَ اللَّهِ الْعَظِيمِ",
      count: 100,
      info:"ثقيلتان في الميزان حبيبتان إلى الرحمن (أى عدد)."
  },
  {
      id: 6, 
      text: "لَا إلَه إلّا اللهُ وَحْدَهُ لَا شَرِيكَ لَهُ، لَهُ الْمُلْكُ وَلَهُ الْحَمْدُ وَهُوَ عَلَى كُلُّ شَيْءِ قَدِيرِ. ",
      count: 100,
      info:"كانت له عدل عشر رقاب، وكتبت له مئة حسنة، ومحيت عنه مئة سيئة، وكانت له حرزا من الشيطان."
  },
  {
      id: 7, 
      text: "الْحَمْدُ للّهِ رَبِّ الْعَالَمِينَ",
      count: 100,
      info:"تملأ ميزان العبد بالحسنات."
  },
  {
      id: 8, 
      text: "لا حَوْلَ وَلا قُوَّةَ إِلا بِاللَّهِ ",
      count: 100,
      info:"كنز من كنوز الجنة (أى عدد)."
  },
  {
      id: 9, 
      text: "الْلَّهُم صَلِّ وَسَلِم وَبَارِك عَلَى سَيِّدِنَا مُحَمَّد ",
      count: 100,
      info:"من صلى على حين يصبح وحين يمسى ادركته شفاعتى يوم القيامة."
  },
  {
      id: 10, 
      text: "أستغفر الله ",
      count: 100,
      info:"لفعل الرسول صلى الله عليه وسلم."
  },
  {
      id: 11, 
      text: "سُبْحَانَ الْلَّهِ، وَالْحَمْدُ لِلَّهِ، وَلَا إِلَهَ إِلَّا الْلَّهُ، وَالْلَّهُ أَكْبَرُ ",
      count: 100,
      info:"أنهن أحب الكلام الى الله، ومكفرات للذنوب، وغرس الجنة، وجنة لقائلهن من النار، وأحب الى النبي عليه السلام مما طلعت عليه الشمس، والْبَاقِيَاتُ الْصَّالِحَات."
  },
  {
      id: 12, 
      text: "لَا إِلَهَ إِلَّا اللَّهُ ",
      count: 100,
      info:"أفضل الذكر لا إله إلاّ الله."
  },
  {
      id: 13, 
      text: "الْلَّهُ أَكْبَرُ ",
      count: 100,
      info:"من قال الله أكبر كتبت له عشرون حسنة وحطت عنه عشرون سيئة. الله أكبر من كل شيء."
  },
  {
      id: 14, 
      text: "سُبْحَانَ اللَّهِ ، وَالْحَمْدُ لِلَّهِ ، وَلا إِلَهَ إِلا اللَّهُ ، وَاللَّهُ أَكْبَرُ ، اللَّهُمَّ اغْفِرْ لِي ، اللَّهُمَّ ارْحَمْنِي ، اللَّهُمَّ ارْزُقْنِي. ",
      count: 100,
      info:"خير الدنيا والآخرة ."
  },
  {
      id: 15, 
      text: "الْحَمْدُ لِلَّهِ حَمْدًا كَثِيرًا طَيِّبًا مُبَارَكًا فِيهِ.",
      count: 100,
      info:"قَالَ النَّبِيُّ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ لَقَدْ رَأَيْتُ اثْنَيْ عَشَرَ مَلَكًا يَبْتَدِرُونَهَا، أَيُّهُمْ يَرْفَعُهَا."
  },
  {
      id: 16, 
      text: "اللَّهُ أَكْبَرُ كَبِيرًا ، وَالْحَمْدُ لِلَّهِ كَثِيرًا ، وَسُبْحَانَ اللَّهِ بُكْرَةً وَأَصِيلاً.",
      count: 100,
      info:"قَالَ النَّبِيُّ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ عَجِبْتُ لَهَا ، فُتِحَتْ لَهَا أَبْوَابُ السَّمَاءِ"
  },
  {
      id: 17, 
      text: "اللَّهُمَّ صَلِّ عَلَى مُحَمَّدٍ وَعَلَى آلِ مُحَمَّدٍ كَمَا صَلَّيْتَ عَلَى إِبْرَاهِيمَ , وَعَلَى آلِ إِبْرَاهِيمَ إِنَّكَ حَمِيدٌ مَجِيدٌ , اللَّهُمَّ بَارِكْ عَلَى مُحَمَّدٍ وَعَلَى آلِ مُحَمَّدٍ كَمَا بَارَكْتَ عَلَى إِبْرَاهِيمَ وَعَلَى آلِ إِبْرَاهِيمَ إِنَّكَ حَمِيدٌ مَجِيدٌ. ",
      count: 100,
      info:"في كل مره تحط عنه عشر خطايا ويرفع له عشر درجات ويصلي الله عليه عشرا وتعرض على الرسول صلى الله عليه وسلم (أى عدد)."
  },
]