export const data =
[
      {
            id: 124, 
            title:"أدعية استفتاح الصلاة",
            data:[
                     "اللَّهُمَّ بَاعِدْ بَيْنِيْ وَبَيْنَ خَطَايَايَ كَمَا بَاعَدْتَ بَيْنَ الْمَشْرِقِ وَالْمَغْرِبِ، اللهم نَقِّنِيْ مِنْ خَطَايَايَ كَمَا يُنَقَّى الثَّوْبُ الْأبْيَضُ مِنَ الدَّنَسِ، اللهم اغْسِلْنِيْ مِنْ خَطَايَايَ، بِالثَّلْجِ وَالْمَاءِ والْبَرَدِ.",
                     "سُبْحَانَكَ سُبْحَانَكَ اللهم وَبِحَمْدِكَ، وَتَبَارَكَ اسْمُكَ، وَتَعَالَى جَدُّكَ، وَلَا إِلَهَ غَيْرُكَ.",
                     "الْحَمْدُ الْحَمْدُ للّهِ حَمْداً كَثِيراً طَيِّباً مُبَارَكاً فِيهِ.",
                     "اللهُ أكْبَرُ كَبِيْرًا، وَالْحَمْدُ لِلهِ كَثِيْرًا، وَسُبْحَانَ اللهِ بُكْرَةً وَّاصِيْلًا. أعُوْذُ بِاللهِ مِنَ الشَّيْطَانِ: مِنْ نَفْخِهِ، وَنَفْثِهِ، وَهَمْزِهِ.",
                     "اللَّهُمَّ رَبَّ جَبْرَائِيلَ، وَمِيْكَائِيلَ، وَإِسْرَافِيْلَ، فَاطِرَ السَّمَوَاتِ وَالْأرْضِ، عَالِمَ الغَيْبِ وَالشَّهَادَةِ أنْتَ تَحْكُمُ بَيْنَ عِبَادِكَ فِيْمَا كَانُوا فِيْهِ يَخْتَلِفُونَ، اِهْدِنِيْ لِمَا اخْتُلِفَ فِيْهِ مِنَ الْحَقِّ بِإِذْنِكَ إِنَّكَ تَهْدِيْ مَنْ تَشَاءُ إِلَى صِرَاطٍ مُّسْتَقِيْمٍ.",
                     "وَجَّهْتُ وَجْهِيَ لِلَّذِيْ فَطَرَ السَّمَوَاتِ وَالْأرْضَ حَنِيْفًا وَّمَا أنَا مِنَ الْمُشْرِكِيْنَ، إِنَّ صَلَاتِيْ، وَنُسُكِيْ، وَمَحْيَايَ، وَمَمَاتِيْ لِلهِ رَبِّ الْعَالَمِيْنَ، لَا شَرِيْكَ لَهُ وَبِذَلِكَ أُمِرْتُ وَانَا مِنَ الْمُسْلِمِيْنَ، اللهم أنْتَ الْمَلِكُ لَا إِلَهَ إِلَّا أنْتَ، أنْتَ رَبِّيْ وَأنَا عَبْدُكَ، ظَلَمْتُ نَفْسِيْ وَاعْتَرَفْتُ بِذَنْبِيْ فَاغْفِرْ لِيْ ذُنُوْبِيْ جَمِيْعًا إِنَّهُ لَا يَغْفِرُ الذُّنوبَ إِلَّا أنْتَ. وَاهْدِنِيْ لِأحْسَنِ الْأخْلَاقِ لَا يَهْدِيْ لِأحْسَنِهَا إِلَّا أنْتَ، وَاصْرِفْ عَنِّيْ سَيِّئَهَا، لَا يَصْرِفُ عَنِّيْ سَيِّئَهَا إِلَّا أنْتَ، لَبَّيْكَ وَسَعْدَيْكَ، وَالْخَيْرُ كُلُّهُ بِيَدَيْكَ، وَالشَّرُّ لَيْسَ إِلَيْكَ، أنَا بِكَ وَإِلَيْكَ، تَبارَكْتَ وَتَعَالَيْتَ، أسْتَغْفِرُكَ وَأتُوْبُ إِلَيْكَ.",
                 ],
      },
      {
           id:125,
           title:"أدعية الركوع",
           data: [
                     "سُبْحانَ رَبِّيَ الْعَظِيْمِ. ثلاث مراتٍ أو أكثر.",
                     "سُبْحَانَ رَبِّيَ العَظِيمِ وَبِحَمْدِهِ. ثلاث مراتٍ.",
                     "سُبحانَكَ اللّهمَّ ربَّنا وَبِحمدِكَ، اللّهمَّ اغفِرْ لي.",
                     "سُبُّوُحٌ، قُدُّوسٌ، رَبُّ المَلَائِكَةِ وَالرُّوْحِ.",
                     "سُبْحَانَ ذِيْ الْجَبَرُوْتِ، وَالْمَلَكُوْتِ، وَالْكِبْرِيَاءِ، وَالْعَظَمَةِ.",
                     "اللَّهُمَّ لَكَ رَكَعْتُ، وَبِكَ آمَنْتُ، وَلَكَ أَسْلَمْتُ، خَشَعَ لَكَ سَمْعِي وَبَصَرِي، وَمُخِّي وَعَظْمِي وَعَصَبِي.",
                 ]
      },
      {
           id:126,
           title:"أدعية الرفع من الركوع",
           data: [
                     "سَمِعَ اللهُ لِمَنْ حَمِدَهُ.",
                     "رَبَّنَا وَلَكَ الْحَمْدُ، حَمْدًا كَثِيْرًا طَيِّبًا مُبارَكًا فِيْهِ.",
                     "اللَّهُمَّ لَكَ رَكَعْتُ، وَبِكَ آمَنْتُ، وَلَكَ اسْلَمْتُ، خَشَعَ لَكَ سَمْعِيْ، و بَصَـرِيْ، وَمُخِّيْ، وَعَظْمِيْ، وَعَصَبِيْ، وَمَا استَقَلَّتْ بِهِ قَدَمِيْ لِلهِ رَبِّ الْعَالَمِيْنَ.",
                     "اللَّهُمَّ رَبَّنَا لَكَ الْحَمْدُ مِلْءَ السَّمَاوَاتِ وَمِلْءَ الْارْضِ، وَمَا بَيْنَهُمَا، وَمِلْءَ مَا شِئْتَ مِنْ شَيْءٍ بَعْدُ، أهْلَ الثَّنَاءِ وَالْمَجْدِ، أحَقُّ مَا قَالَ الْعَبْدُ، وَكُلُّنَا لَكَ عَبْدٌ، اللهم لَا مَانِعَ لِمَا أعْطَيْتَ، وَلَا مُعْطِيَ لِمَا مَنَعْتَ، وَلَا يَنْفَعُ ذَا الجَدِّ مِنْكَ الْجَدُّ.",
                     "اللَّهُمَّ لَكَ الْحَمْدُ مِلْءَ السَّمَاءِ، وَمِلْءَ الْارْضِ، وَمِلْءَ مَا شِئْتَ مِنْ شَيْءٍ بَعْدُ، اللَّهُمَّ طَهِّرْنِي بِالثَّلْجِ وَالْبَرَدِ، وَالْمَاءِ الْبَارِدِ، اللَّهُمَّ طَهِّرْنِي مِنَ الذُّنُوبِ وَالْخَطَايَا، كَمَا يُنَقَّى الثَّوْبُ الْابْيَضُ مِنَ الْوَسَخِ.",
                 ]
      },
      {
           id:127,
           title:"أدعية السجود",
           data: [ 
                     "سُبْحَانَ رَبِّيَ الأَعْلَى. ثلاث مرات أو أكثر.",
                     "سُبْحَانَ رَبِّيَ الأعْلَى وَبِحَمْدِهِ. ثلاث مرات.",
                     "سُبُّوحٌ قُدُّوسٌ رَبُّ الْمَلاَئِكَةِ وَالرُّوحِ.",
                     "سُبحانَكَ اللّهمَّ ربَّنا وَبِحمدِكَ، اللّهمَّ اغفِرْ لي.",
                     "سُبْحَانَ ذِي الْجَبْرُوتِ وَالْمَلَكُوتِ وَالْكِبْرِيَاءِ وَالْعَظَمَةِ.",
                     "اللَّهُمَّ اغْفِرْ لِي ذَنْبِي كُلَّهُ دِقَّهُ وَجِلَّهُ، وَأَوَّلَهُ وَآخِرَهُ، وَعَلاَنِيَتَهُ وَسِرَّهُ.",
                     "اللَّهُمَّ لَكَ سَجَدْتُّ وَبِكَ آمَنْتُ، وَلَكَ أسْلَمْتُ، سَجَدَ وَجْهِي لِلَّذِيْ خَلَقَهُ، وَصَوَّرَهُ، وَشَقَّ سَمْعَهُ وَبَصَرَهُ، تَبَارَكَ اللهُ أحْسَنُ الْخَالِقيْنَ.",
                     "اللَّهُمَّ إِنِّيْ أعُوْذُ بِرِضَاكَ مِنْ سَخَطِكَ، وَبِمُعَافَاتِكَ مِنْ عُقوْبَتِكَ، وَأعُوْذُ بِكَ مِنْكَ، لَا أُحْصِـي ثَنَاءً عَلَيْكَ، أنْتَ كَمَا أثْنَيْتَ عَلَى نَفْسِكَ.",
                     "رَبِّ اعْطِ نَفْسِي تَقْوَاهَا زَكِّهَا أنْتَ خَيْرُ مَنْ زَكَّاهَا أنْتَ وَلِيُّهَا وَمَوْلَاهَا.",
                     "اللَّهُمَّ اجْعَلْ فِي قَلْبِي نُورًا وَاجْعَلْ فِيْ سَمْعِيْ نُورًا وَاجْعَلْ فِيْ بَصَرِيْ نُورًا وَاجْعَلْ مِنْ تَحْتِي نُورًا وَاجْعَلْ مِنْ فَوْقِي نُورًا وَعَنْ يَمِينِي نُورًا وَعَنْ يَسَارِي نُورًا وَاجْعَلْ أمَامِي نُورًا وَاجْعَلْ خَلْفِي نُورًا وَأعْظِمْ لِيْ نُورًا.",
                 ]
      },
      {
           id:128,
           title:"أدعية الجلوس بين السجدتين",
           data: [    
                     " رَبِّ اغْفِرْ لِيْ، رَبِّ اغْفِرْ لِيْ.",
                     "اللَّهُمَّ اغْفِرْ لِيْ، وَارْحَمْنِيْ، وَاهْدِنِيْ، وَاجْبُرْنِيْ، وَعَافِنِيْ، وَارْزُقْنِيْ، وَارْفَعْنِيْ.",
                 ]
      },
      {
           id:129,
           title:"دعاء سجود التلاوة",
           data: [ 
                     "سَجَدَ وَجْهِيَ للَّذِي خَلَقَهُ، وَشَقَّ سَمْعَهُ وبَصَرَهُ، بِحَوْلِهِ وَقُوَّتِهِ، فَتَبَارَكَ اللَّهُ أَحْسَنُ الْـخَالِقِينَ.",
                     "اللَّهُمَّ اكْتُبْ لِي بِهَا عِنْدَكَ أجْراً، وَضَعْ عَنِّي بِهَا وِزْرَاً، واْجعَلْهَا لِي عِنْدِكَ ذُخْراً، وتَقَبَّلَهَا مِنِّي كَمَا تَقَبَّلْتَهَا مِنْ عَبْدِكَ دَاوُدَ.",
                 ]
      },
      {
           id:130,
           title:"التشهد الأول",
           data: [
                     "التَّحِيَّاتُ لِلَّهِ وَالصَّلَوَاتُ وَالطَّيِّبَاتُ السَّلَامُ عَلَيْكَ أَيُّهَا النَّبِيُّ وَرَحْمَةُ اللَّهِ وَبَرَكَاتُهُ السَّلَامُ عَلَيْنَا وَعَلَى عِبَادِ اللَّهِ الصَّالِحِينَ ، أَشْهَدُ أَنْ لَا إِلَهَ إِلَّا اللَّهُ وَأَشْهَدُ أَنَّ مُحَمَّدًا عَبْدُهُ وَرَسُولُهُ."
                 ]
      },
      {
           id:131,
           title:"التشهد الأخير",
           data: [ 
                     "التَّحِيَّاتُ لِلَّهِ وَالصَّلَوَاتُ وَالطَّيِّبَاتُ السَّلَامُ عَلَيْكَ أَيُّهَا النَّبِيُّ وَرَحْمَةُ اللَّهِ وَبَرَكَاتُهُ السَّلَامُ عَلَيْنَا وَعَلَى عِبَادِ اللَّهِ الصَّالِحِينَ ، أَشْهَدُ أَنْ لَا إِلَهَ إِلَّا اللَّهُ وَأَشْهَدُ أَنَّ مُحَمَّدًا عَبْدُهُ وَرَسُولُهُ. اللَّهُمَّ صَلِّ عَلَى مُحَمَّدٍ وَعَلَى آلِ مُحَمَّدٍ كَمَا صَلَّيْتَ عَلَى إِبْرَاهِيمَ وَعَلَى آلِ إِبْرَاهِيمَ إِنَّكَ حَمِيدٌ مَجِيدٌ ، اللَّهُمَّ بَارِكْ عَلَى مُحَمَّدٍ وَعَلَى آلِ مُحَمَّدٍ كَمَا بَارَكْتَ عَلَى إِبْرَاهِيمَ وَعَلَى آلِ إِبْرَاهِيمَ إِنَّكَ حَمِيدٌ مَجِيدٌ."
                 ]
      },
      {
           id:132,
           title:"أدعية بعد التشهد الأخير وقبل السلام",
           data: [
                     "للَّهُمَّ إِنِّيْ أعُوْذُ بِكَ مِنْ عَذَابِ الْقَبْرِ، وَمِنْ عَذَابِ جَهَنَّمَ، وَمِنْ فِتْنَةِ الْمَحْيَا وَالْمَمَاتِ، وَمِنْ شَرِّ فِتْنَةِ الْمَسِيْحِ الدَّجَّالِ.",
                     "اللَّهُمَّ إِنِّي أَعُوذُ بِكَ مِنْ عَذَابِ الْقَبْرِ. وَأَعُوذُ بِكَ مِنْ فِتْنَةِ الْمَسِيحِ الدَّجَّالِ. وَأَعُوذُ بِكَ مِنْ فِتْنَةِ الْمَحْيَا وَالْمَمَاتِ. اللَّهُمَّ إِنِّي أَعُوذُ بِكَ مِنَ الْمَأْثَمِ وَالْمَغْرَم ِ.",
                     "اللَّهُمَّ إِنِّي ظَلَمْتُ نَفْسِي ظُلْماً كَثِيراً ، وَلاَ يَغْفِرُ الذُّنُوبَ إِلاَّ أَنْتَ. فَاغْفِرْ لِي مَغْفِرَةً مِنْ عِنْدِكَ وَارْحَمْنِي، إِنَّكَ أَنْتَ الْغَفُورُ الرَّحِيمُ.",
                     "اللَّهُمَّ اغْفِرْ لِي مَا قَدَّمْتُ وَمَا أَخَّرْتُ. وَمَا أَسْرَرْتُ وَمَا أَعْلَنْتُ. وَمَا أَسْرَفْتُ. وَمَا أَنْتَ أَعْلَمُ بِهِ مِنِّي. أَنْتَ الْمُقَدِّمُ وَأَنْتَ الْمُؤَخِّرُ. لاَ إِلهَ إِلاَّ أَنْتَ.",
                     "رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الآخِرَةِ حَسَنَةً وَقِنَا عَذَابَ النَّارِ.",
                     "اللَّهُمَّ إِنِّيْ أسْألُكَ الْجَنَّةَ وَأعُوْذُ بِكَ مِنَ النَّارِ.",
                     "اللَّهُمَّ إِنِّيْ أسْألُكَ يَا أللهُ بِأنَّكَ الْوَاحِدُ الْأحَدُ الصَّمَدُ الَّذِيْ لَمْ يَلِدْ وَلَمْ يُوْلَدْ، وَلَمْ يَكنْ لَهُ كُفُوًا أحَدٌ، أنْ تَغْفِرَ لِيْ ذُنُوْبِيْ إِنَّكَ أنْتَ الْغَفُوْرُ الرَّحِيْمُ.",
                     "اللَّهُمَّ حَاسِبْنِيْ حِسَابَاً يَسِيراً.",
                     "اللَّهُمَّ إِنِّيْ أسْألُكَ بِأنَّ لَكَ الْحَمْدُ لَا إِلَهَ إِلَّا أنْتَ وَحْدَكَ لَا شَرِيْكَ لَكَ، الْمَنَّانُ، يَا بَدِيعَ السَّمَوَاتِ",
                     "وَالْارْضِ يَا ذَا الْجَلَالِ وَالْإِكْرَامِ، يَا حَيُّ يَا قَيُّومُ إِنِّيْ أسْألُكَ الْجَنَّةَ وَأعُوْذُ بِكَ مِنَ النَّارِ.",
                     "اللَّهُمَّ إِنِّيْ أسْألُكَ بِأنَّيْ أشْهَدُ أنَّكَ أنْتَ اللهُ لَا إِلَهَ إِلَّا أنْتَ الْأحَدُ الصَّمَدُ الَّذِيْ لَمْ يَلِدْ وَلَمْ يُوْلَدْ وَلَمْ يَكُنْ لَهُ كُفُوًا أحَدٌ.",
                     "اللَّهُمَّ بِعِلْمِكَ الغَيْبَ وَقُدْرَتِكَ عَلَى الْخَلْقِ أحْيِنِيْ مَا عَلِمْتَ الْحَيَاةَ خَيْرًا لِيْ، وَتَوَفَّنِيْ إِذَا عَلِمْتَ الْوَفَاةَ خَيْرًا لِيْ، اللهم إِنِّيْ أسْألُكَ خَشْيَتَكَ فِيْ الْغَيْبِ وَالشَّهَادَةِ، وَأسْألُكَ كَلِمَةَ الْحَقِّ فِي الرِّضَا وَالْغَضَبِ، وَأسْألُكَ الْقَصْدَ فِيْ الْغِنَى وَالْفَقْرِ، وَأسْألُكَ نَعِيْمًا لَا يَنْفَدُ، وَأسْألُكَ قُرَّةَ عَيْنٍ لَا تَنْقَطِعُ، وَأسْألُكَ الرِّضَا بَعْدَ الْقَضَاءِ، وَأسْألُكَ بَرْدَ الْعَيْشِ بَعْدَ الْمَوْتِ، وَأسْألُكَ لَذَّةَ النَّظَرِ إِلَى وَجْهِكَ، وَالشَّوْقَ إِلَى لِقائِكَ فِيْ غَيْرِ ضَرَّاءَ مُضِرَّةٍ، وَلَا فِتْنَةٍ مُضِلَّةٍ، اللهم زَيِّنَّا بِزِينَةِ الْإِيْمَانِ، وَاجْعَلْنَا هُدَاةً مُهْتَدِيْنَ."
                 ]
      },
      {
           id:133,
           title:"دعاء القنوت",
           data: [ 
                     "اللَّهُمَّ اهْدِني فيمَنْ هدَيْت، وعَافِنِي فيمَنْ عافيتَ، وتَوَلَّني فيمن تَولَّيت، وبارِك لي فيمَا أعطيت، وقِني شرَّ ما قَضَيْت، فإنَّك تقضي ولا يُقْضَى عَليك، إنَّه لا يَذلُّ من واليت، تباركت ربنا وتعاليت.",
                     "اللَّهُمَّ إني أعُوذُ بِرضَاك من سَخَطِك وأعُوذُ بمُعافاتك مِنْ عُقوبَتِك، وأعُوذُ بِكَ مِنْكَ لا أُحْصِي ثناءً عليكَ، أنْتَ كما أثنيت على نفسك.",
                     "اللَّهُمَّ إيَّاك نعبُدُ، ولك نُصلِّي ونسجُدُ، وإليك نسعَى ونَحْفِد، نرجُو رحمتك، ونخشى عذابَكَ، إنَّ عذابَكَ بالكافرين مُلْحِقٌ، اللَّهُمَّ إنَّا نستعينُكَ، ونستغفرك، ونُثْنِي عليكَ الخيرَ، ولا نَكْفُرُك، ونُؤمِنُ بِكَ ونَخْضَعُ لَك، ونَخْلَعُ من يكفُرُك.",
                     "اللَّهُمَّ تَقَبَّلْ مِنَّا إِنَّكَ أَنتَ السَّمِيعُ الْعَلِيمُ وَتُبْ عَلَيْنَآ إِنَّكَ أَنتَ التَّوَّابُ الرَّحِيمُ. وَصَلَّى اللهُ عَلَى سَيِّدِنَا مُحَمَّدٍ وَعَلى آلِهِ وَصَحْبِهِ وَسَلمَ.",
                 ]
      },
      {
           id:134,
           title:"دعاء خطبة الجمعة",
           data: [
                     "ربناَّ لا تزغ قلوبنا بعد إذ هديتنا وهب لنا من لدنك رحمة انك أنت الوهاب وأصلح اللهم أحوالنا في الأمور كلها وبلغنا بما يرضيك أمالنا واختم اللهم بالصالحات أعمالنا وبالسعادة أجالنا وتوفنا يا رب وأنت راض عنا.",
                     "اللَّهُمَّ اجعل جمعنا هذا جمعا مباركا مرحوما وتفرقنا من كل شر معصوما.",
                     "ربنا لا تدع لنا ذنبا إلا غفرته ولا هما إلا فرجته ولا مريضا إلا شفيته ولا ميتا إلا رحمته ولا طالبا أمرا من أمور الخير إلا سهلته له ويسرته.",
                     "اللَّهُمَّ وحد كلمة المسلمين واجمع شملهم واجعلهم يدا واحدة على من سواهم وانصر اللهم المسلمين واخذل الكفرة المشركين أعدائك أعداء الدين.",
                     "اللَّهُمَّ إنا نسألك لولاة أمورنا الصلاح والسداد.",
                     "اللَّهُمَّ كن لهم عونا وخذ بأيديهم إلى الحق والصواب والسداد والرشاد ووفقهم للعمل لما فيه رضاك وما فيه صالح العباد والبلاد.",
                     "اللَّهُمَّ إنا نسألك لبلدنا هذا اللهم اجعله بلدا ءامنا وارزقه من كل الخيرات وجنبه الفتن ما ظهر منها وما بطن وألف اللهم ما بين قلوبنا وبين قلوب أبناء هذا الوطن واجعلهم اللهم يا ربنا باسمك متحابين وعلى نصرة دينك متعاونين.",
                     "اللَّهُمَّ إنا نستعيذ بك من شر ما خلقت ومن كل عين حاسد و نسألك اللهم التوفيق والسداد والهداية والرشاد وحسن العقبى وحسن الميعاد.",
                     "اللَّهُمَّ أسبغ علينا نعمتك وعلى جميع المسلمين واملء اللهم قلوبنا بالإيمان والقناعة والزم جوارحنا العبادة والطاعة واغفر اللهم لنا ولوالدينا ولإخواننا وأشياخنا ولجميع من سبقنا بالإيمان واتنا من لدنك رحمة وهيئ لنا من امرنا رشدا واتنا ربنا في الدنيا حسنة وفي الآخرة حسنة وقنا عذاب النار.",
                     "سبحان ربك رب العزة عما يصفون وسلام على المرسلين والحمد لله رب العالمين.",
                 ]
      }
    ]