export const data =
[
     {
         id    : 1,
         title : "ما هو تعريف القرأن الكريم؟",
         data  : [
                   `القرآن هو الكتاب المقدس في الإسلام، وهو الكلام النبوي الوحيد الذي تلقاه النبي محمد صلى الله عليه وسلم من الله عز وجل. يعتبر القرآن كلام الله المنزل على النبي محمد عليه الصلاة والسلام عبر الملك جبريل (جبرائيل).`,
                   `من جوانب أخرى، يُعتبر القرآن أعظم إعجاز للنبي محمد صلى الله عليه وسلم، حيث يتميز بأسلوبه اللغوي والأدبي، وبتوجيهاته وحكمه العميقة التي تتناول جميع جوانب الحياة، سواء كانت دينية أو اجتماعية أو أخلاقية. يُقسم القرآن إلى سور (الفصول)، وتقسم السور إلى آيات تحوي النصوص الوحيية.`,
                   `تُشتق كلمة "'قرآن'" من المصدر «'قرأ'»،«'يقرأ'»،«'قراءة'»، و«'قرآنًا'» وأصله من «'القَرء'» بمعنى الجمع والضم، يُقال: «قرأت الماء في الحوض»، بمعنى جمعته فيه، يُقال: «ما قرَأت الناقة جنينًا»، أي لم يضمَّ رحمها ولد. وسمى القرآن قرآنًا لأنه يجمع الآيات والسور ويضم بعضها إلى بعض.`
         ]
     },
     {
         id    : 2,
         title : "من هو الذي كتب القرآن الكريم؟",
         data  : [
                    `شَكّل عثمان بن عفان لجنة لكتابة المصحف تضم: زيد بن ثابت وعبد الله بن الزبير وسعيد بن العاص وعبد الرحمن بن الحارث بن هشام، ثم دفع إلى زيد بن ثابت والقرشيين الثلاثة المصحف الذي كان عند حفصة بنت عمر، وأمرهم بنسخ مصاحف منها، وقال: «إذا اختلفتم أنتم وزيد بن ثابت في شيء من القرآن، فاكتبوه بلسان قريش، فإنما نزل بلسانهم».`
         ]
     },
     {
         id    : 3,
         title : "ما هي بعض المواضيع الرئيسية في القرآن؟",
         data  : [
                     `التوحيد (الإيمان بوحدانية الله): القرآن يركز بشكل أساسي على التوحيد ووحدانية الله، ويحث الناس على عبادته وحده دون مشاركة أو شركاء.`,
                     `الرسل والكتب السماوية: يُذكر القرآن قصص الرسل والأنبياء والكتب السماوية السابقة، مثل التوراة والإنجيل، ويؤكد على استمرارية رسالة الله.`,
                     `الخلق والحياة الدنيا والآخرة: يتحدث القرآن عن الخلق والحياة في الدنيا، ويذكر الناس بالآخرة والحساب الذي سيكون يوم القيامة.`,
                     `الأخلاق والأخلاقيات: يحث القرآن على الأخلاق الحسنة والسلوك الصالح، ويحظر عن السلوك الفاسق والفجور.`,
                     `الشريعة والقوانين: يقدم القرآن إرشادات وتشريعات لتنظيم حياة الفرد والمجتمع وتحقيق العدل.`,
                     `التاريخ القومي: يتناول القرآن تاريخ بني إسرائيل والأمم السابقة كدروس للعبر والعظات.`,
                     `التسامح والرحمة: يبرز القرآن قيم التسامح والرحمة، ويحث على معاملة الآخرين باللطف والعدل.`,
                     `العلم والحكمة: يشجع القرآن على البحث عن العلم ويُحث على استخدام الحكمة في التفكير واتخاذ القرارات.`,
                     `الإنسان والنفس: يتحدث القرآن عن خلق الإنسان ونفسه ويوجهه لتحقيق النجاح في الدنيا والآخرة.`,
         ]
     },
     {
         id    : 4,
         title : "هل يمكن ترجمة القرآن؟",
         data  : [
                     `نعم، يمكن ترجمة القرآن إلى لغات مختلفة، وقد تم ترجمته إلى العديد من اللغات حول العالم. هدف الترجمة هو جعل معاني القرآن مفهومة للناس الذين لا يتحدثون اللغة العربية، التي هي اللغة الأصلية للقرآن.`,
                     `ومع ذلك، يجب أن يُفهم أن الترجمة قد تفقد بعض التفاصيل والدقة التي قد يحتويها النص الأصلي باللغة العربية. اللغة العربية لها قواعد وبنية لغوية فريدة، وبعض المفردات قد تحمل معاني دقيقة وعميقة لا يمكن نقلها بشكل تام في الترجمة.`,
                     `إضافةً إلى ذلك، يمكن أن تكون الترجمة تأثيرًا للتفسير الشخصي للمترجم، وقد يؤثر ذلك على كيفية فهم القارئ للنص. لهذا السبب، يفضل لأولئك الذين يتقنون اللغة العربية أن يقرءوا النص الأصلي للقرآن الكريم لفهم أعمق وأدق.`
         ]
     },
     {
         id    : 5,
         title : "ماذا يعلمنا القران؟",
         data  : [
                     `التوحيد (وحدانية الله): يُعلم القرآن الإيمان بوحدانية الله ورفض الشرك (المشاركة مع الله في العبادة).`,
                     `الرسل والكتب السماوية: يُذكر القرآن بالرسل والأنبياء الذين أرسلهم الله لهداية البشر، ويشير إلى الكتب السماوية السابقة.`,
                     `الحياة الدنيا والآخرة: يُعلم القرآن أن الحياة الدنيا هي اختبار وأن هناك حياة آخرة حيث سيحاسب الناس على أعمالهم.`,
                     `العدالة والأخلاق: يحث القرآن على العدالة والأخلاق الحسنة، ويحظر عن الفساد والظلم.`,
                     `العبادة والطاعة: يُعلم القرآن أهمية العبادة والامتثال لأوامر الله، ويوجه الناس إلى أداء الصلاة والصوم والزكاة والحج.`,
                     `التسامح والرحمة: يحث القرآن على التسامح والرحمة في التعامل مع الآخرين.`,
                     `العلم والتفكير: يشجع القرآن على البحث عن العلم والتفكير في آيات الله وخلقه.`,
                     `الصبر والاستقامة: يُعلم القرآن أهمية الصبر في وجه التحديات والاستقامة على الحق.`,
                     `التواضع والاعتدال: يحث القرآن على التواضع والاعتدال في الحياة والتعامل مع الأمور.`,
                     `التوبة والغفران: يُشجع القرآن على التوبة من الذنوب ويُظهر رحمة الله وقدرته على الغفران.`,

         ]
     },
]