
export const hadithBoukharyCategories = [
  { id: 1, nameAr: "كتاب بدء الوحي", nameEn: "Revelation" },
  { id: 2, nameAr: "كتاب الإيمان", nameEn:  "Belief" },
  { id: 3, nameAr: "كتاب العلم", nameEn:  "Knowledge" },
  { id: 4, nameAr: "كتاب الوضوء", nameEn:  "Ablutions (Wudu')" },
  { id: 5, nameAr: "كتاب الغسل", nameEn:  "Bathing (Ghusl)" },
  { id: 6, nameAr: "كتاب الحيض", nameEn:  "Menstrual Periods" },
  { id: 7, nameAr: "كتاب التيمم", nameEn:  "Rubbing hands and feet with dust (Tayammum)"},
  { id: 8, nameAr: "كتاب الصلاة", nameEn:"Prayers (Salat)"   },
  { id: 9, nameAr: "كتاب مواقيت الصلاة", nameEn: "Times of the Prayers"  },
  { id: 10, nameAr: "كتاب الأذان", nameEn:  "Call to Prayers (Adhaan)" },
  { id: 13, nameAr: "كتاب الجمعة", nameEn: "Friday Prayer"  },
  { id: 14, nameAr: "كتاب الخوف", nameEn:  "Fear Prayer" },
  { id: 15, nameAr: "كتاب العيدين", nameEn: "The Two Festivals (Eids)"  },
  { id: 16, nameAr: "كتاب الوتر", nameEn: "Witr Prayer"  },
  { id: 17, nameAr: "كتاب الاستسقاء", nameEn:  "Invoking Allah for Rain (Istisqaa)" },
  { id: 18, nameAr: "كتاب الكسوف", nameEn: "Eclipses"  },
  { id: 19, nameAr: "كتاب سجود القرآن", nameEn: "Prostration During Recital of Qur'an"  },
  { id: 20, nameAr: "كتاب تقصير الصلاة", nameEn: "Shortening the Prayers (At-Taqseer)"  },
  { id: 21, nameAr: "كتاب التهجد", nameEn:  "Prayer at Night (Tahajjud)" },
  { id: 23, nameAr: "كتاب الصلاة في مسجد مكة والمدينة", nameEn: "Virtues of Prayer at Masjid Makkah and Madinah"  },
  { id: 24, nameAr: "كتاب العمل في الصلاة", nameEn:  "Actions while Praying" },
  { id: 25, nameAr: "كتاب سجود السهو", nameEn: "Forgetfulness in Prayer"  },
  { id: 26, nameAr: "كتاب الجنائز", nameEn: "Funerals (Al-Janaa'iz)"  },
  { id: 27, nameAr: "كتاب الزكاة", nameEn: "Obligatory Charity Tax (Zakat)"  },
  { id: 29, nameAr: "كتاب الحج", nameEn: "Hajj (Pilgrimage)"  },
  { id: 30, nameAr: "كتاب العمرة", nameEn: "`Umrah (Minor pilgrimage)"  },
  { id: 32, nameAr: "أبواب جزاء الصيد", nameEn: "Penalty of Hunting while on Pilgrimage"  },
  { id: 33, nameAr: "أبواب فضائل المدينة", nameEn: "Virtues of Madinah"  },
  { id: 34, nameAr: "كتاب الصوم", nameEn: "Fasting"  },
  { id: 35, nameAr: "كتاب البيوع", nameEn: "Sales and Trade"  },
  { id: 36, nameAr: "كتاب المظالم", nameEn: "Oppressions"  },
  { id: 37, nameAr: "كتاب الوصايا", nameEn: "Wills and Testaments (Wasaayaa)"   },
  { id: 38, nameAr: "كتاب الجهاد والسير", nameEn: "Fighting for the Cause of Allah (Jihaad)"   },
  { id: 39, nameAr: "كتاب الأنبياء", nameEn:  "Prophets" },
  { id: 40, nameAr: "كتاب فضائل الصحابة", nameEn: "Virtues and Merits of the Prophet (pbuh) and his Companions"  },
  { id: 41, nameAr: "كتاب المغازي", nameEn: "Military Expeditions led by the Prophet (pbuh) (Al-Maghaazi)"  },
  { id: 42, nameAr: "كتاب تفسير القرآن", nameEn:"Prophetic Commentary on the Qur'an (Tafseer of the Prophet (pbuh))"   },
  { id: 43, nameAr: "كتاب فضائل سور القرآن", nameEn: "Virtues of the Qur'an"  },
  { id: 44, nameAr: "كتاب الطلاق", nameEn: "Divorce"  },
  { id: 45, nameAr: "كتاب النفقات", nameEn: "Supporting the Family"  },
  { id: 46, nameAr: "كتاب العقيقة", nameEn: "Sacrifice on Occasion of Birth (`Aqiqa)"   },
  { id: 47, nameAr: "كتاب الصيد والذبائح", nameEn: "Hunting, Slaughtering"  },
  { id: 48, nameAr: "كتاب الأضاحي", nameEn:"Al-Adha Festival Sacrifice (Adaahi)"   },
  { id: 49, nameAr: "كتاب الأشربة", nameEn: "Drinks"  },
  { id: 50, nameAr: "كتاب المرضى", nameEn: "Patients"  },
  { id: 51, nameAr: "كتاب الطب", nameEn: "Medicine"  },
  { id: 52, nameAr: "كتاب اللباس", nameEn:  "Dress" },
  { id: 53, nameAr: "كتاب الأدب", nameEn:  "Good Manners and Form (Al-Adab)" },
  { id: 54, nameAr: "كتاب الاستئذان", nameEn: "Asking Permission"  },
  { id: 55, nameAr: "كتاب الدعوات", nameEn: "Invocations"  },
  { id: 56, nameAr: "كتاب الرقاق", nameEn:"To make the Heart Tender (Ar-Riqaq)"   },
  { id: 57, nameAr: "كتاب القدر", nameEn: "Divine Will (Al-Qadar)"  },
  { id: 58, nameAr: "كتاب الأيمان والنذور", nameEn: "Oaths and Vows"  },
  { id: 59, nameAr: "كتاب كفارات الأيمان", nameEn: "Expiation for Unfulfilled Oaths"  },
  { id: 60, nameAr: "كتاب الفرائض", nameEn:"Laws of Inheritance (Al-Faraa'id)"   },
  { id: 61, nameAr: "كتاب الحدود", nameEn:   "Limits and Punishments set by Allah (Hudood)"},
  { id: 62, nameAr: "كتاب المحاربين من أهل الردة", nameEn:  "Apostates" },
  { id: 63, nameAr: "كتاب الديات", nameEn: "Blood Money (Ad-Diyat)"  },
  { id: 64, nameAr: "كتاب استتابة المرتدين وقتالهم", nameEn: "Apostates"  },
  { id: 65, nameAr: "كتاب الإكراه", nameEn: "(Statements made under) Coercion"  },
  { id: 66, nameAr: "كتاب الحيل", nameEn: "Tricks"  },
  { id: 69, nameAr: "كتاب الأحكام", nameEn: "Judgments (Ahkaam)"  },
  { id: 70, nameAr: "كتاب التمني", nameEn: "Wishes"  },
  { id: 71, nameAr: "كتاب نهاية العالم", nameEn:  "Afflictions and the End of the World" },
  { id: 72, nameAr: "كتاب الاعتصام بالكتاب والسنة", nameEn:  "Holding Fast to the Qur'an and Sunnah" },
  { id: 73, nameAr: "كتاب التوحيد", nameEn:  "Oneness, Uniqueness of Allah (Tawheed)" },
];
