export const data = 
[
     
     {
         id: 25,
         title:"الذكر عند الطعام والشراب",
         data: [
                   "بِسْمِ اللهِ.",
                   "فإنْ نسي في أَوَّلِهِ، فَليَقُلْ:بِسْمِ اللَّه أَوَّلَهُ وَآخِرَهُ."
               ]
     },
     {
         id: 26,
         title:"الذكر عند شرب اللبن",
         data: [
                   "اَللَّهُمَّ بَارِكْ لَنَا فِيهِ, وَزِدْنَا مِنْهُ. "
               ]
     },
     {
         id: 27,
         title:"الذكر عند الفراغ من الطعام والشراب",
         data: [
                   "الْحَمْدُ للهِ الَّذِي أَطْعَمَنِي هَذَا, وَرَزَقَنِيهِ مِنْ غَيْرِ حَوْلٍ مِّنِّي وَلاَ قُوَّةٍ.",
                   "الْحَمْدُ لِلَّهِ كَثِيرًا طَيِّبًا مُبَارَكًا فِيهِ غَيْرَ مَكْفِيٍّ وَلَا مُوَدَّعٍ وَلَا مُسْتَغْنًى عَنْهُ رَبَّنَا.",
               ]
     },
     {
         id: 28,
         title:"أذكار الضيف",
         data: [
                   "أَفْطَرَ عِنْدَكُمُ الصَّائِمُونَ ، وَأَكَلَ طَعَامَكُمُ الأَبْرَارُ ، وَصَلَّتْ عَلَيْكُمُ الْمَلائِكَةُ."
               ]
     },
     {
         id: 29,
         title:"هدى النبى فى الشرب",
         data: [
                   "كَانَ صَلَّى اللهُ عَلَيْهِ وَسَلَّمَ يَشْرَبُ فِي ثَلاَثَةِ أَنْفَاسٍ، إِذَا أَدْنَى الإِنَاءَ إِلَى فَمِهِ سَمَّى اللهَ تَعَالَى, وَإِذَا أَخَّرَهُ حَمِدَ اللهَ تَعَالَى، يَفْعَلُ ذَلِكَ ثَلاَثَ مَرَّاتٍ. "
               ]
     },


]