import m1 from "../../img/abdelbasset-profile.webp";
import m2 from "../../img/abdul-rahman-al-sudais-profile.avif";
import m4 from "../../img/fares-abad.jpg";
import m5 from "../../img/hani-ar-rifai-profile.avif";
import m7 from "../../img/maher-elmekely.jpg";
import m8 from "../../img/mahmoud-khalil-al-hussary-profile.avif";
import m9 from "../../img/mishary-rashid-alafasy-profile.avif";
import m10 from "../../img/mohamed-siddiq-el-minshawi-profile.avif";
import m11 from "../../img/abduallah_kamel.jpg";
import m12 from "../../img/mohamed-ayoub.jpg";
import m13 from "../../img/bana.jpg";
import m14 from "../../img/nasser.jpg";
import m15 from "../../img/khaled.jpg";
import m16 from "../../img/abdaziz.jpg";
import m17 from "../../img/khalifa.jpg";
import m18 from "../../img/abobake.jpg";
import m19 from "../../img/abdalmohsen.jpg";
import m20 from "../../img/ahmedagamy.jpg";
import m21 from "../../img/saaoud.jpg";

export const data = [
  {
    title: "عبدالباسط عبدالصمد",
    date: "1927 - 1988",
    info: "قارئ قرآن مصري ويُعَد أحد أعلام هذا المجال البارزين، وقد لُقّب ب«صوت مكة». حفظ القرآن الكريم على يد الشيخ محمد الأمير شيخ كتاب قريته. أخذ القراءات على يد الشيخ المتقن محمد سليم حمادة. دخل الإذاعة المصرية سنة 1951، وكانت أول تلاواته من سورة فاطر. عُيّن قارئًا لمسجد الإمام الشافعي سنة 1952، ثم لمسجد الإمام الحسين سنة 1958 خلفًا للشيخ محمود علي البنا. ترك للإذاعة ثروة من التسجيلات إلى جانب المصحفين المرتل والمجود ومصاحف مرتلة لبلدان عربية وإسلامية. جاب بلاد العالم سفيرًا لكتاب الله، وكان أول نقيب لقراء مصر سنة 1984.",
    img: m1,
    id: 250,
    api :"https://server7.mp3quran.net/basit/"
  },
  {
    title: "عبدالرحن السديس",
    date: "1962",
    info: "عبد الرحمن بن عبد العزيز بن عبد الله بن محمد السديس ويُعرَف بالسديس هو رئيس الشؤون الدينية بالمسجد الحرام والمسجد النبوي، بمرتبة وزير. وإمام وخطيب بالحرم المكي الشريف ",
    img: m2,
    id: 251,
    api: "https://server11.mp3quran.net/sds/"
  },
  {
    title: "فارس عباد",
    date: "1980",
    info: "هو أحد القراء اليمنيين  تخرج من حلقات التحفيظ بمسجد بلال بن رباح بشارع هائل بمدينة صنعاء، ويرى المعجبون به أنه الأفضل من بين قرّاء القرآن الكريم حيث يعتبرونه صاحب صوت شجي وندي، وهو إمام مشارك في جامع الشاب عبد الله بن بدر السويدان بمنطقة الدوحة في المنطقة الشرقية من المملكة العربية السعودية.",
    img: m4,
    id: 252,
    api:"https://server8.mp3quran.net/frs_a/"
  },
  {
    title: "هاني الرفاعي",
    date: "1974",
    info: "هو الشيخ هاني بن عبد الرحيم بن يحيى الرفاعي، القارئ والإمام والخطيب، ولد بمدينة جدة بالمملكة العربية السعودية في العام 1394 هـ (1974 م )، متزوج وله خمسة أبناء (ولدان وثلاث بنات). وهو حاليا إمام وخطيب جامع العناني بحي الحمراء بجدة.",
    img: m5,
    id: 253,
    api: "https://server8.mp3quran.net/hani/"
  },
  {
    title: "ماهر المعقلي",
    date: "1969",
    info: "ماهر بن حمد بن معيقل المعيقلي البلوي، إمام وخطيب المسجد الحرام وحاصل على شهادة الدكتوراه من جامعة أم القرى في مكة المكرمة ويتميز الشيخ بصوته العذب وقراءته المتقنة للقرآن الكريم.",
    img: m7,
    id: 254,
    api: "https://server12.mp3quran.net/maher/"
  },
  {
    title: "خليل محمود الحصري",
    date: "1917 - 1980",
    info: "الشيخ محمود خليل الحصري قارئ قرآن مصري ويعد أحد أعلام هذا المجال البارزين، من مواليد قرية شبرا النملة، طنطا، محافظة الغربية، له العديد من المصاحف المسجلة بروايات مختلفة. كان والده قبل ولادته قد انتقل من محافظة الفيوم إلى هذه القرية التي ولد فيها. وهو قارئ قرآن مصري أجاد قراءة القرآن الكريم بالقراءات العشر.",
    img: m8,
    id: 255,
    api: "https://server13.mp3quran.net/husr/"
  },
  {
    title: "مشاري راشد العفاسي",
    date: "1976",
    info: "مشاري بن راشد بن غريب بن محمد بن راشد العفاسي المطيري إمام المسجد الكبير وخطيب في وزارة الأوقاف والشؤون الإسلامية بدولة الكويت. ومستشار اتحاد المبدعين العرب عضو الأمم المتحدة، صاحب أول قناة إسلامية كويتية قناة العفاسي الفضائية. وقارئ قرآن كريم.",
    img: m9,
    id: 256,
    api: "https://server8.mp3quran.net/afs/"
  },
  {
    title: "محمد صديق المنشاوي",
    date: "1920 - 1969",
    info: "محمد صِدِّيق المنشاوي قارئ قرآن مصري يُعد أحد أعلام هذا المجال البارزين والمشهورين على مستوى العالم الإسلامي، وأحد روّاد التلاوة المتميزين بتلاوته المرتلة والمجوّدة. سجل المصحف المرتل برواية حفص عن عاصم. وكان قارئًا في الإذاعة المصرية. توفي مبكرًا إثر مرض عن 49 عامًا.",
    img: m10,
    id: 257,
    api : "https://server10.mp3quran.net/minsh/"
  },
  {
    title: "عبد الله كامل",
    date: "1985 - 2023",
    info: "عبد الله أحمد كامل، داعية وقارئ قرآن مسلم مصري كفيف من مواليد محافظة الفيوم بإحدى قرى مركز يوسف الصديق تدعى المشرك قبلياشتهر الشيخ عبد الله كامل بصوته العذب في قراءة القرآن الكريم، وله تسجيلات عديدة على الإنترنت تتوفر أغلبها على قناته الرسمية على اليوتيوب.",
    img: m11,
    id: 258,
    api : "https://server16.mp3quran.net/kamel/Rewayat-Hafs-A-n-Assem/"
  },
  {
    title: "محمد ايوب",
    date: "1952 - 2016",
    info: "محمد أيوب بن محمد يوسف بن سليمان عمر، هو إمام المسجد النبوي في الفترة بين، والفترة ما بين، ومن قراء القرآن الكريم المعروفين، ولد بمكة المكرمة سنة 1372هـ، ويُذكر أنه من أصل آراكان بورما، حفظ القرآن في عام 1386 هـ في جامع بن لادن في حي الحفاير بمكة المكرمة، والتحق بكلية الشريعة بالجامعة الإسلامية، .",
    img: m12,
    id: 259,
    api : "https://server16.mp3quran.net/ayyoub2/Rewayat-Hafs-A-n-Assem/"
  },
  {
    title: "محمود علي البنا",
    date: "1952 - 2016",
    info: "قارئ قرآن مصري ويعد أحد أعلام هذا المجال البارزين، من مواليد قرية شبرا باص مركز شبين الكوم بمحافظة المنوفية. حفظ القرآن الكريم في كتاب القرية على يد الشيخ موسى المنطاش، وأتم حفظه وهو في الحادية عشرة، ثم انتقل إلى مدينة طنطا لدراسة العلوم الشرعية بالجامع الأحمدي، وتلقى القراءات فيها على يد الإمام إبراهيم بن سلام المالكي .",
    img: m13,
    id: 260,
    api : "https://server8.mp3quran.net/bna/"
  },
  {
    title: "ناصر القطامي",
    date: "1980",
    info: "ناصر القُطامي قارئ من قراء القرآن الكريم في المملكة العربية السعودية، وإمام وخطيب جامع عبد الله بن ناصر المهيني بالرياض. ولد في السعودية عام 1980 م 1400 هـ. ويعد من أشهر قراء منطقة الخليج والوطن العربي. متزوج ولديه ثلاثة أولاد وبنت. ",
    img: m14,
    id: 261,
    api : "https://server6.mp3quran.net/qtm/"
  },
  {
    title: "خالد القحطاني",
    date: ".",
    info: "خالد القحطاني قارئ من المملكة العربية السعودية، يعمل خالد القحطاني إماما بأحد مساجد مدينة الدمام",
    img: m15,
    id: 262,
    api : "https://server10.mp3quran.net/qht/"
  },
  {
    title: "عبدالعزيز التركي",
    date: "",
    info: "",
    img: m16,
    id: 263,
    api : "https://server16.mp3quran.net/a_turki/Rewayat-Hafs-A-n-Assem/"
  },
  {
    title: "خليفة الطنيجي",
    date: "",
    info: "خليفة مصبح أحمد سيف الطنيجي قارئ للقرآن الكريم حكم دولي في مسابقات القرآنية, ومهندس مدني وكذا رئيس دائرة الإسكان بحكومة الشارقة.",
    img: m17,
    id: 264,
    api : "https://server12.mp3quran.net/tnjy/"
  },
  {
    title: "أبو بكر الشاطري",
    date: "1970م",
    info: "شيخ بن أبي بكر بن محمد الشاطري وباختصار أبو بكر الشاطري هو قارئ يمني يسكن في السعودية وتحديدًا مدينة جدة، اشتهر بصوته العذب وبتلاوته للقرآن الكريم.",
    img: m18,
    id: 265,
    api : "https://server11.mp3quran.net/shatri/"
  },
  {
    title: "عبدالمحسن الحارثي",
    date: "",
    info: " معلم بمتوسطة الإمام ورش لتحفيظ القرآن الكريم وكان اول تعييني بمنطقة المدينة المنورة ثم معلماً في ثانوية الملك فهد لمدة سنة … ثم انتقلت إلى مدينة جدة وعملت في( ابتدا ئية القيسي ) لمدة سنة",
    img: m19,
    id: 266,
    api : "https://server6.mp3quran.net/mohsin_harthi/"
  },
  {
    title: "أحمد العجمي",
    date: "1968 ",
    info: "أحمد علي العجمي قارئ القرآن الكريم المعروف والذي ولد سنة في مدينة الخُبر بالسعودية.",
    img: m20,
    id: 267,
    api : "https://server10.mp3quran.net/ajm/"
  },
  {
    title: "سعود الشريم",
    date: "1966 ",
    info: "سعود بن إبراهيم بن محمد بن إبراهيم آل شريم من فخذ الحراقيص من بني زيد المعروفة في نجد ولد في الرياض سنة إمام الحرم المكي من 1412 هـ إلى 1444 هـ، عميد كلية الدراسات القضائية والأنظمة جامعة أم القرى سابقا، وإمام وخطيب المسجد الحرام وقاضي سابق بالمحكمة الكبرى بمكة المكرمة.",
    img: m21,
    id: 268,
    api : "https://server7.mp3quran.net/shur/"
  },
];
