export const data =
[
     {
         id    : 30,
         title : "الذكر عند الاحرام",
         data  : [
                     `"سُبْحَانَ اللهِ وَالحَمْدُ للهِ وَالله أَكْبَرُ"`
                 ]
     },
     {
         id    : 31,
         title : "دعاء التلبية في الحج والعمرة",
         data  : [
                     `"لَبَّيْكَ اللَّهُمَّ لَبَّيْك، لَبّيْك لا شَرِيكَ لك لَبَّيْك إنَّ الحَمْدَ، والنِّعْمَةَ، لَكَ والمُلْك، لا شريك لك".`
                 ]
     },
     {
         id    : 32,
         title : "الدعاء عند رؤية الكعبة",
         data  : [
                     `"اللهُمَّ أَنْتَ السَّلَامُ وَمِنْكَ السَّلَامُ، فَحَيِّنَا رَبَّنَا بِالسَّلَامِ". `
                 ]
     },
     {
         id    : 33,
         title : "الدعاء عند استلام الحجر الأسود",
         data  : [
                     `"بِاسْمِ اللهِ وَاللهُ أَكْبَرُ".`,
                     `"لَا إِلَهَ إِلَّا اللَّهُ وَاللَّهُ أَكْبَرُ، اللَّهُمَّ تَصْدِيقًا بِكِتَابِكَ، وَسُنَّةِ نَبِيِّكَ صَلَّى اللهُ عَلَيْهِ وَسَلَّمَ".`
                 ]
     },
     {
         id    : 34,
         title : "الدُّعاءُ بينَ الرُّكن اليماني والحجر الأسود",
         data  : [
                     `رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً، وَفِي الْآخِرَةِ حَسَنَةً، وَقِنَا عَذَابَ النَّارِ".`
                 ]
     },
     {
         id    : 35,
         title : "الدعاء في الطواف",
         data  : [
                     `"اللهُمَّ رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ حَسَنَةً وَقِنَا عَذَابَ النَّارِ". `,
                     `"لَا إِلَهَ إِلَّا اللَّهُ وَحْدَهُ، لَا شَرِيكَ لَهُ، لَهُ الْمُلْكُ وَلَهُ الْحَمْدُ، بِيَدِهِ الْخَيْرُ، وَهُوَ عَلَى كُلِّ شَيْءٍ قَدِيرٌ".`
                 ]
     },
     {
         id    : 35,
         title : "الدعاء بعد ركعتي الطواف",
         data  : [
                     `اللَّهُمَّ اعْصِمْنِي بِدِينِكَ، وَطَاعَةِ رَسُولِكَ صَلَّى اللهُ عَلَيْهِ وَسَلَّمَ.`,
                     `اللَّهُمَّ جَنِّبْنِي حُدُودَكَ.`,
                     `اللَّهُمَّ اجْعَلْنِي مِمَّنْ يُحِبُّكَ، وَيُحِبُّ مَلَائِكَتَكَ، وَرُسُلَكَ، وَعِبَادَكَ الصَّالِحِينَ اللَّهُمَّ حَبِّبْنِي إِلَيْكَ، وَإِلَى مَلَائِكَتِكَ، وَرُسُلِكَ، وَعِبَادَكَ الصَّالِحِينَ.`,
                     `اللَّهُمَّ آتِنِي مِنْ خَيْرِ مَا تُؤْتِي عِبَادَكَ الصَّالِحِينَ فِي الدُّنْيَا وَالْآخِرَةِ.`,
                     `اللَّهُمَّ يَسِّرْنِي لِلْيُسْرَى، وَجَنِّبْنِي الْعُسْرَى، وَاغْفِرْ.`
                 ]
     },
     {
         id    : 36,
         title : "الدعاء عند صعود الصَّفَا والمروة",
         data  : [
                     `عَنْ جَابِرِ بْنِ عَبْدِ اللَّهِ رضي الله عنه، قَالَ: سَمِعْتُ رَسُولَ اللَّهِ صَلَّى اللهُ عَلَيْهِ وَسَلَّمَ حِينَ خَرَجَ مِنَ الْمَسْجِدِ يُرِيدُ الصَّفَا، يَقُولُ: "نَبْدَأُ بِمَا بَدَأَ بِهِ" فَبَدأَ بِالصَّفَا، وَقَرَأَ: {إِنَّ الصَّفَا وَالمَرْوَةَ مِنْ شَعَائِرِ اللَّهِ} وَكَانَ إِذَا وَقَفَ عَلَى الصَّفَا يُكَبِّرُ ثَلاثًا، وَيَقُولُ:
                     "لَا إِلَهَ إِلَّا اللهُ وَحْدَهُ لَا شَرِيكَ لَهُ، لَهُ الْمُلْكُ وَلَهُ الْحَمْدُ وَهُوَ عَلَى كُلِّ شَيْءٍ قَدِيرٌ، لَا إِلَهَ إِلَّا اللهُ، أَنْجَزَ وَعْدَهُ، وَنَصَرَ عَبْدَهُ، وَهَزَمَ الْأَحْزَابَ وَحْدَهُ".
                     ثُمَّ أعاد هذا الكلام. `
                 ]
     },
     {
         id    : 37,
         title : "الدعاء على الصفا",
         data  : [
                     `"اللَّهُمَّ إِنَّكَ قُلْتَ: {اِدْعُونِي أَسْتَجِبْ لَكُمْ} وَإِنَّكَ لاَ تُخْلِفُ الْمِيعَادَ، وَإِنِّي أَسْأَلُكَ كَمَا هَدَيْتَنِي إِلَى الإِسْلاَمِ أَلاَ تَنْزِعَهُ مِنِّي حَتَّى تَتَوَفَّانِي، وَأَنَا مُسْلِمٌ".`,
                     `"اللَّهُمَّ اعْصِمْنَا بِدِينِكَ، وَطَوَاعِيَتِكَ، وَطَوَاعِيَةِ رَسُولِكَ، وَجَنِّبْنَا حُدُودَكَ".`,
                     `"اللَّهُمَّ اجْعَلْنَا نُحِبُّكَ، وَنُحِبُّ مَلَائِكَتَكَ، وَأَنْبِيَاءَكَ، وَرُسَلَكَ، وَنُحِبُّ عِبَادَكَ الصَّالِحِينَ".`,
                     `"اللَّهُمَّ حَبِّبْنَا إِلَيْكَ وَإِلَى مَلَائِكَتِكَ، وَإِلَى أَنْبِيَائِكَ، وَرُسُلِكَ، وَإِلَى عِبَادِكَ الصَّالِحِينَ".`,
                     `"اللَّهُمَّ يَسِّرْنَا لِلْيُسْرَى، وَجَنِّبْنَا وَجَنِّبْنَا الْعُسْرَى، وَاغْفِرْ لَنَا فِي الْآخِرَةِ". `
                 ]
     },
     {
         id    : 38,
         title : "الدعاء في السعي بين الصفا والمروة",
         data  : [
                     `"رَبِّ اغْفِرْ وَارْحَمْ إِنَّكَ أَنْتَ الْأَعَزُّ الْأَكْرَمُ".`
                 ]
     },
     {
         id    : 39,
         title : "الدعاء بعرفات",
         data  : [
                     `"لَا إِلَهَ إِلَّا اللَّهُ وَحْدَهُ لَا شَرِيكَ لَهُ، لَهُ الْمُلْكُ وَلَهُ الْحَمْدُ، وَهُوَ عَلَى كُلِّ شَيْءٍ قَدِيرٌ".`
                 ]
     },
     {
         id    : 40,
         title : "دعاء السلف الصالح في عرفات",
         data  : [
                     `"لَا إِلَهَ إِلَّا اللَّهُ وَحْدَهُ لَا شَرِيكَ لَهُ، لَهُ الْمُلْكُ وَلَهُ الْحَمْدُ، وَهُوَ عَلَى كُلِّ شَيْءٍ قَدِيرٌ".`,
                     `"اللَّهُمَّ اهْدِنَا بِالْهُدَى، وَزَيِّنَّا بِالتَّقْوَى، وَاغْفِرْ لَنَا فِي الْآخِرَةِ وَالْأُولَى".`,
                     `"ثُمَّ يَخْفِضُ صَوْتَهُ، ثُمَّ يَقُولُ: اللَّهُمَّ إِنِّي أَسْأَلُكَ مِنْ فَضْلِكَ وَعَطَائِكَ رِزْقًا طَيِّبًا مُبَارَكًا".`,
                     `"اللَّهُمَّ إِنَّكَ أَمَرْتَ بِالدُّعَاءِ، وَقَضَيْتَ عَلَى نَفْسِكَ بِالِاسْتِجَابَةِ، وَأَنْتَ لَا تُخْلِفُ وَعْدَكَ، وَلَا تَكْذِبُ عَهْدَكَ، اللَّهُمَّ مَا أَحْبَبْتَ مِنْ خَيْرٍ فَحَبِّبْهُ إِلَيْنَا، وَيَسِّرْهُ لَنَا، وَمَا كَرِهْتَ مِنْ شَيْءٍ فَكَرِّهْهُ إِلَيْنَا وَجَنِّبْنَاهُ، وَلَا تَنْزِعْ عَنَّا الْإِسْلَامَ بَعْدَ إِذْ أَعْطَيْتَنَا".`
                 ]
     },
     {
         id    : 41,
         title : "الدعاء عند رمي جمرة العقبة عِنْدَ كُلِّ حَصَاة",
         data  : [
                     `"الله أَكْبَر، الله أَكْبَر، الله أَكْبَر".`,
                     `"اللَّهُمَّ اجْعَلْهُ حَجًّا مَبْرُورًا وَذَنْبًا مَغْفُورًا". `
                 ]
     },
     {
         id    : 42,
         title : "الذكر عند ذبح الأضاحي",
         data  : [
                     `بسم الله , اللهم تقبل من محمد وآل محمد ومن أمة محمد`
                 ]
     },
     {
      id    : 43,
      title : "دعاء الرجوع من الحج أو العمرة",
      data  : [
                  `"الله أَكْبَر، الله أَكْبَر، الله أَكْبَر"`,
                  `"لَا إِلَهَ إِلَّا اللهُ وَحْدَهُ لَا شَرِيكَ لَهُ، لَهُ الْمُلْكُ، وَلَهُ الْحَمْدُ، وَهُوَ عَلَى كُلِّ شَيْءٍ قَدِيرٌ، آيِبُونَ، تَائِبُونَ،عَابِدُونَ، سَاجِدُونَ".`,
                  `"اَللّٰهُمَّ اجْعَلْهُ حَجًّا مَبْرُوْرًا وَسَعْيًا مَشْكُوْرًا وَذَنْبًا مَغْفُوْرًا".`
              ]
     },
]