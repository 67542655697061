export const data = 
[

     {
         id: 44, 
         title: "الذكر عند الخلاء",
         text: "(بِسْمِ الله) اللّهُـمَّ إِنِّـي أَعـوذُ بِـكَ مِـنَ الْخُـبْثِ وَالْخَبائِث."
     },
     {
         id: 45, 
         title: "الذكربعد الخروج من الخلاء",
         text: "غُفْـرانَك."
     },

]